require('./bootstrap')

require('./ConvertPricesTax')
require('./select2')

import feather from 'feather-icons';

(function ($) {
    feather.replace()

    $(window).on('scroll', function () {
        if ($(document).scrollTop() >= 150) {
            $('body').addClass('header-sticky')
        } else {
            $('body').removeClass('header-sticky')
        }
    })

    // Handle the newsletter signup
    $('#newsletter-form').on('submit', function (e) {
        e.preventDefault()

        let url = $(this).attr('action')
        let btn = $('#btn-newsletter-submit')

        btn.prop('disabled', true)

        axios
            .post(url, $(this).serialize())
            .then(response => {
                window.toastr.success(response.data)
                $('#newsletter-form').trigger('reset')
            })
            .catch(error => {
                window.toastr.error(Object.values(error.response.data.errors)[0])
            })
            .then(function () {
                btn.prop('disabled', false)
            })
    })

    const paginationDefaultList = [].slice.call(document.querySelectorAll('.sort-default'))

    paginationDefaultList.map(function (paginationDefaultEl) {
        paginationDefaultEl.addEventListener('change', () => {
            const paginationDefault = paginationDefaultEl.value

            if (paginationDefault) {
                const formPaginationDefaultEl = paginationDefaultEl.closest('form')
                formPaginationDefaultEl.submit()
            }
        })
    })
    
    $(document).on('click', '.search-btn', function() {
        $(this).closest('form').submit();
    });

    $(document).on('change', '.navigation-toggle', function() {
        if ($(this).is(':checked')) {
            $('body').addClass('has-navigation-toggle');
        } else {
            $('body').removeClass('has-navigation-toggle');
        }
    })

})(jQuery)
