import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
    dsn: "https://83e4bb3d9ad5433ea16cfb1cf652b336@o1149579.ingest.sentry.io/6221913",
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.1,
});

window._ = require('lodash')

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.$ = window.jQuery = require('jquery')
} catch (e) {}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios')

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

let token = document.head.querySelector('meta[name="csrf-token"]')

if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token')
}

/**
 * Next we will register the request interceptor and response interceptor.
 */
// Set a getter/setter and listerner to the window.
window.ajaxRunning = {
    nbrInternal: 0,
    nbrListener: function (val) {},
    set nbr(val) {
        if (val < 0) {
            val = 0
        }
        this.nbrInternal = val
        this.nbrListener(val)
    },
    get nbr() {
        return this.nbrInternal
    },
    registerListener: function (listener) {
        this.nbrListener = listener
    },
}

// Add a request interceptor
axios.interceptors.request.use(
    function (config) {
        window.ajaxRunning.nbr++

        return config
    },
    function (error) {
        return Promise.reject(error)
    }
)

// Add a response interceptor
axios.interceptors.response.use(
    function (response) {
        window.ajaxRunning.nbr--

        return response
    },
    function (error) {
        window.ajaxRunning.nbr--

        return Promise.reject(error)
    }
)

/**
 * We'll load the Math.js, an extensive math library for JavaScript and Node.js.
 */

window.mathjs = require('mathjs')

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });

window.select2 = require('select2')

import toastr from 'toastr'
window.toastr = toastr
window.toastr.options.preventDuplicates = true
window.toastr.options.timeOut = 5000
window.toastr.options.extendedTimeOut = 2500
