require('./bootstrap')

jQuery(function () {
    // Load the select2 library on all the .chzn-select
    $('.chzn-select').each(function () {
        const currentEl = $(this)

        currentEl
            .select2({
                theme: 'bootstrap',
                width: '100%',
                placeholder: function () {
                    currentEl.data('placeholder')
                },
            })
            .data('select2')
            .$container.addClass(function () {
                if (currentEl.hasClass('form-select-sm')) {
                    return 'select2-sm'
                }

                return false
            })
    })
})
