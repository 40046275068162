require('./bootstrap')

$(function () {
    convertPricesTax.init()
})

window.convertPricesTax = {
    default_tax: process.env.MIX_APP_CURRENCY_TAX,

    init() {
        $('[data-convertPriceHtToVAT]').on('change keyup paste', e => {
            const el = $(e.target)
            if (el.val()) {
                el.closest('td')
                    .find('[data-convertPriceVATToHt]')
                    .val(convertPricesTax.htToVat(el.val(), parseFloat(el.attr('data-tax') || convertPricesTax.default_tax)))
            } else {
                el.closest('td').find('[data-convertPriceVATToHt]').val('')
            }
        })

        $('[data-convertPriceVATToHt]').on('change keyup paste', e => {
            const el = $(e.target)
            if (el.val()) {
                el.closest('td')
                    .find('[data-convertPriceHtToVAT]')
                    .val(convertPricesTax.vatToHt(el.val(), parseFloat(el.attr('data-tax') || convertPricesTax.default_tax)))
            } else {
                el.closest('td').find('[data-convertPriceHtToVAT]').val('')
            }
        })
    },

    vatToDecimal(tax) {
        return window.mathjs.chain(tax).divide(100).add(1).done()
    },

    htToVat(value, tax) {
        return (
            window.mathjs.round(
                window.mathjs
                    .chain(value * 100)
                    .multiply(this.vatToDecimal(tax))
                    .done(),
                0
            ) / 100
        )
    },

    vatToHt(value, tax) {
        return (
            window.mathjs.round(
                window.mathjs
                    .chain(value * 100)
                    .divide(this.vatToDecimal(tax))
                    .done(),
                0
            ) / 100
        )
    },
}
